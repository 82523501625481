<template>
  <subpart-a>
    <h2 class="white_title">III. Le parcours aval</h2>
    <h3 class="white_title">La consultation post opératoire</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>J’ai rendez-vous à 10h30. Pour gagner du temps je ne passe pas par l’accueil et me présente directement au
        bureau d’enregistrement.... Loupé, ce n’était pas nécessaire, je pouvais aller directement à l’accueil des
        consultations.</p>
      <p>
        Une fois identifié, je vais m’asseoir dans la salle d’attente qui est bourrée de monde. J’espère que je
        n’attendrais pas autant que la première fois ! Encore un vœu pieux, les minutes s’égrènent. A 11h00 mon nom est
        enfin affiché. La porte du bureau de consultation est ouverte mais il n’y a personne. j’attends encore un quart
        d’heure debout dans le couloir. Mon chirurgien arrive en compagnie de deux autres personnes. Nous pénétrons dans
        la pièce. Il me souhaite la bienvenue et me précise qu’il est accompagné de deux internes. Il m’examine
        rapidement et m’indique qu’il me faut masser la cicatrice régulièrement. À ma question : avec une pommade ? Il
        me répond avec ce que vous voulez.</p>
      <p>
        Comme j’esquisse un geste de massage, un des internes me montre comment je dois faire. Le chirurgien me dit
        qu’il n’y a pas de problème, mais que les terminaisons nerveuses devant se reconstituer, il me faudra attendre
        plusieurs mois pour retrouver des sensations normales. Il ajoute qu’il n’a pas besoin de me revoir, sauf si dans
        trois mois j’ai quelque chose d’important. Dans ce cas il me faudra revenir en ayant fait un nouvel examen. Il
        me donne une ordonnance au cas où et je m’en vais.</p>
      <p>
        Expédié en 10 minutes ... bon, ... c’est un chirurgien...</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA11",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA12.png');
}
/*
@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}*/


</style>