function keywords_check_base(good_gives, bad_gives) {
    return function (list, goods) {
        let score = 0.0;
        if (list) {
            list.map(function (v) {
                score += goods.includes(v.id) ? good_gives : bad_gives;
                console.log(v.id + ': ' + goods.includes(v.id) + ' -> ' + score);
            });
        }
        return score;
    }
}

export let keywords_check_AR1s = keywords_check_base(100 / 12, -5);
export let keywords_check_AR2s = keywords_check_base(100 / 19, -5);
export let keywords_check_AR3s = keywords_check_base(100 / 3, -5);

const step_id_list = ['A01', 'A02', 'A03', 'A04', 'A05', 'A06', 'A07', 'A08', 'A09', 'A10', 'A11', 'A12'];

export let panels = {
    preserve: {
        // A01: ['K_attitudeprofessionnels_0', 'K_priseencharge_2'],
        /* demo */
        A02: [],
        /* cs chir */
        A03: ['K_attitudeprofessionnels_0', 'K_priseencharge_2', 'K_infoexpldoc_0'],
        /* préadm */
        A04: ['K_infoexpldoc_1', 'K_infoexpldoc_0', 'K_documentation_1', 'K_documentation_0', 'K_organisation_0'],
        /* cs anest */
        A05: ['K_attitudeprofessionnels_0', 'K_attitudeprofessionnels_2', 'K_infoexpldoc_2'],

        /* arrivée */
        A06: [],
        /* prépa */
        A07: ['K_infoexpldoc_0', 'K_infoexpldoc_2', 'K_attitudeprofessionnels_0'],
        /* inter */
        A08: ['K_priseencharge_3'],
        /* attente */
        A09: ['K_attitudeprofessionnels_0', 'K_priseencharge_3'],
        /* sortie */
        A10: ['K_organisation_2', 'K_infoexpldoc_2'],

        /* dom */
        A11: [],
        /* post */
        A12: []
    },
    improve: {
        // A01: ['K_infra_1'],
        /* demo */ A02: ['K_infra_2', 'K_priseencharge_2'],
        /* cs chir */ A03: ['K_infra_1', 'K_attitudeprofessionnels_3', 'K_priseencharge_1'],
        /* préadm */A04: [],
        /* cs anest */ A05: ['K_infra_1', 'K_organisation_0', 'K_priseencharge_2'],

        /* arrivée */ A06: ['K_attitudeprofessionnels_3', 'K_infra_1', 'K_infra_0', 'K_infoexpldoc_1'],
        /* prépa */ A07: ['K_priseencharge_0', 'K_infra_0', 'K_organisation_0', 'K_infoexpldoc_3'],
        /* inter */ A08: ['K_priseencharge_0', 'K_attitudeprofessionnels_3', 'K_priseencharge_2'],
        /* attente */ A09: ['K_attitudeprofessionnels_3', 'K_infra_2'],
        /* sortie */ A10: ['K_infra_1', 'K_attitudeprofessionnels_3', 'K_priseencharge_3', 'K_infra_0'],

        /* dom */ A11: ['K_organisation_2', 'K_priseencharge_3'],
        /* post */ A12: ['K_priseencharge_2', 'K_priseencharge_1', 'K_infoexpldoc_1', 'K_attitudeprofessionnels_1']
    }
}

export let getPanels = function () {
    return panels;
}

function keywords_check_single(panel, list, step, rounded) {
    let goods = panel[step], good_gives = 100 / goods.length, bad_gives = -5;

    // console.log('single ' + step);
    console.log('panel');
    console.table(goods, ["Value"]);
    console.log('user');
    console.table(list, ["id", "label", "picto"]);

    if ((goods.length === 0) && (list.length === 0)) {
        return rounded ? 100 / rounded : 100;
    }

    let score = 0.0;
    if (list && list.length) {
        list.map(function (v) {
            score += goods.includes(v.id) ? good_gives : bad_gives;
            console.log(v.id + ': ' + goods.includes(v.id) + ' -> ' + score);
        });
    }
    return rounded ? Math.max(0, parseInt(score)) : score;
}

export let keywords_check_preserves = function (list) {
    console.groupCollapsed('preserves');
    let result = {};
    step_id_list.map(function (id) {
        console.groupCollapsed('preserve ' + id);
        result['part' + id] = keywords_check_single(panels.preserve, list['part' + id], id, true);
        console.groupEnd();
    });
    console.table(result);
    console.groupEnd();
    return result;
}

export let keywords_check_improves = function (list) {
    console.groupCollapsed('improves');
    let result = {};
    step_id_list.map(function (id) {
        console.groupCollapsed('improve ' + id);
        result['part' + id] = keywords_check_single(panels.improve, list['part' + id], id, true);
        console.groupEnd();
    });
    console.table(result);
    console.groupEnd();
    return result;
}

export let keywords_check_step = function (preserves, improves, steps) {
    // console.log('INPUT', preserves, improves)
    // console.log('Steps:', steps);
    let mag = 100 / steps.length / 2;

    /*steps.map(function (step_id) {
        mag += panels.preserve[step_id].length + panels.improve[step_id].length;
    });*/

    // console.log('mag: ' + mag + ' (' + steps.length + ')');

    let cumul = 0.0, v = 0.0;
    steps.map(function (step_id) {

        // console.groupCollapsed(step_id);

        // console.groupCollapsed('Preserves ' + step_id);
        // cumul += keywords_check_single(panels.preserve, preserves['part' + step_id], step_id, 100 / mag);
        v = keywords_check_single(panels.preserve, preserves['part' + step_id], step_id, false) * mag / 100;
        // console.groupEnd();
        // console.log(cumul + ' + ' + v + ' = ' + (cumul + v));
        cumul += v;
        // console.groupCollapsed('Improves ' + step_id);
        // cumul += keywords_check_single(panels.improve, improves['part' + step_id], step_id, 100 / mag);
        v = keywords_check_single(panels.improve, improves['part' + step_id], step_id, false) * mag / 100;
        // console.groupEnd();
        // console.log(cumul + ' + ' + v + ' = ' + (cumul + v));
        cumul += v;

        // console.groupEnd();
        // console.log(step_id + ' => ' + cumul);
    });

    return Math.max(0, parseInt(cumul));
}

/**
 *
 * @param { object } cat
 // * @param { array } cat.keyword_array
 * @param { array } cat.catkeywords
 */
export let keywordImportFromArray = function (cat) {
    try {
        for (const key_idx in cat.catkeywords) {
            cat.catkeywords[key_idx] = {
                label: cat.catkeywords[key_idx].label || cat.catkeywords[key_idx],
                id: 'K_' + cat.catref + '_' + key_idx,
                wordclass: 'key_' + cat.catref,
                selected: false,
                label_out: cat.label_out,
                picto: cat.picto,
            }
        }
    } catch (e) {
        console.log('Looks like you are using a buggy safari...');
    }
}

/*export let storeKeywords = function (keywords, subpart) {
    alert('UTILS.JS: ' + subpart);
    /*this.$store.dispatch('saveProgression');
    for (const cat_idx in keywords) {
        let cat = keywords[cat_idx];
        localStorage.setItem('expat.partA.' + subpart + '.' + cat.catref, JSON.stringify(cat.catkeywords));
    }* /
}*/