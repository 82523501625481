<template>
  <subpart-a>
    <h2 class="white_title">I. Le parcours amont</h2>
    <h3 class="white_title">La pré-admission</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>La secrétaire me remet le dossier ambulatoire, m’informe que la préadmission peut être réalisée par internet
        sur le site de l’hôpital. Une fois la préadmission réalisée, impérativement avant la fin de la semaine, je
        recevrai un SMS de confirmation. Un autre SMS me sera envoyé la veille de l’intervention me précisant l’heure à
        laquelle je devrai me présenter. Dans le cas où je n’opterai pas pour cette solution, je pourrai toujours
        réaliser la préadmission lors de la consultation d’anesthésie.</p>
      <p>
        Elle me donne le rendez-vous d’anesthésie, le dossier « ambulatoire » et me souhaite une bonne fin de journée.
        Finalement je suis rasséréné. La marche à suivre me paraît claire. En sortant de l’hôpital je repère le lieu des
        consultations d’anesthésie.</p>
      <p>
        L’accès au site internet sans problème, l’ergonomie bien pensée. Je crée mon espace patient dans lequel je
        remplis les différents documents et dépose les pièces demandées après les avoir numérisées. Cela m’a pris en
        tout une vingtaine de minutes.</p>
      <p>
        Par précaution je remplis les pièces du dossier papier remis par la secrétaire des consultations au cas où ...
        on ne sait jamais ...Je feuillette le dossier ambulatoire. Toutes les informations sont précises, claires. Rien
        ne semble manquer.</p>
      <p>
        Comme prévu, je reçois le lendemain le SMS de confirmation. J’aurais pu m’épargner de remplir le dossier
        papier. </p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA04",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA04.png');
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: center bottom;*/
}
/*
@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 140%;
    background-position: 20% 100%;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: 15% 100%;
    padding-bottom: 30%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: 20% 90%;
    padding-bottom: 30%;
    background-size: 190%;
  }
}*/


</style>