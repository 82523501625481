<template>
  <subpart-a>
    <h2 class="white_title">II. Le parcours en Hôpital de jour</h2>
    <h3 class="white_title">Le jour J</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>Convoqué à 7h00 à l’hôpital, j’arrive avec mon avance habituelle. Le hall d’entrée est sombre et désert, ce qui
        me déroute un peu. Au moment où je sors ma convocation de mon sac, pour vérifier si je ne me suis pas trompé
        d’endroit, une personne, que je n’ai pas repérée, m’interpelle « C’est pour l’ambulatoire ? Asseyez-vous, dans
        quelques minutes je vous ferai monter ».</p>
      <p>
        Dans le coin du hall dans lequel sont disposés les sièges, il y a déjà deux personnes qui attendent. D’autres
        arrivent et sont reçues de la même manière. A 7 heures, le réceptionniste, qui s’avère être un membre du service
        de sécurité, nous dit « Vous pouvez monter par l’ascenseur au niveau 00 où vous serez accueillis ». Toutes les
        personnes présentes se dirigent vers l’ascenseur. Les premiers tentent, sans succès, d’aller au niveau 00. Les
        portes de l’ascenseur se rouvrent lorsque l’on appuie sur le bouton correspondant. Dans la confusion, quelqu’un
        finit par se rendre compte que nous sommes au niveau 00 et que, contrairement à l’indication donnée,
        l’ambulatoire de chirurgie est indiqué au niveau 0.</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA06",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA06.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*
@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}*/

</style>