<template>
  <h3>Récapitulatif général</h3>
  <p>Voici un récapitulatif de vos choix comparés à ceux relevés par le groupe "Expérience Patient" :</p>


  <div id="all_steps">

    <div id="step_list">
      <template v-for="step in step_list" :key="step.id">

        <div class="step_name" :class="{step_selected: current_step === step.id}"
             @mouseover="current_step = step.id"
        >{{ step.label }}
        </div>
      </template>
    </div>

    <div id="cartrige_list">

      <template v-for="step in step_list" :key="step.id">
        <div v-if="current_step === step.id">

          <div class="one_step">
            <div class="preserve user_choices">
              <span class="cart_overhead">Vous</span>
              <keywordListDisplay :keywords="key_target_preserve[step.id]"/>
            </div>
            <div class="preserve panel_choices">
              <span class="cart_overhead">L'équipe</span>
              <keywordListDisplay :keywords="key_panel_preserve[step.id]"/>
            </div>
          </div>
          <div class="cart_underhead">
            <div>
              <span>À préserver : {{ score_preserve[step.id] }}%</span>
            </div>
          </div>


          <div class="one_step">
            <div class="improve user_choices">
              <span class="cart_overhead">Vous</span>
              <keywordListDisplay :keywords="key_target_improve[step.id]"/>
            </div>
            <div class="improve panel_choices">
              <span class="cart_overhead">L'équipe</span>
              <keywordListDisplay :keywords="key_panel_improve[step.id]"/>
            </div>
          </div>
          <div class="cart_underhead">
            <div>
              <span>À améliorer : {{ score_improve[step.id] }}%</span>
            </div>
          </div>






        </div>

      </template>
    </div>


  </div>

  <p>Vous êtes prêt(e) à passer à l'action...
    <button class="big" @click="$router.push('/about/partRB50')">L'Action</button>
  </p>

</template>

<script>
import keywordListDisplay from './keywordListDisplay.vue';
import {keywords_check_improves, keywords_check_preserves} from '../utils';

import keyword_list from "@/keyword_list";
// import {computed} from "vue";

export default {
  name: "subpartAR4",
  components: {
    keywordListDisplay
  },
  data() {
    return {
      current_step: 'partA01',
      step_list: [
        {id: 'partA01', label: '1a - Consultation de chirurgie (l’arrivée)'},
        {id: 'partA02', label: '1b - Consultation de chirurgie (la salle d’attente)'},
        {id: 'partA03', label: '1 - La consultation de chirurgie'},
        {id: 'partA04', label: '2 - La pré-admission'},
        {id: 'partA05', label: '3 - La consultation d\'anesthésie'},
        {id: 'partA06', label: '4 - Le jour J'},
        {id: 'partA07', label: '5 - La préparation'},
        {id: 'partA08', label: '6 - L\'intervention'},
        {id: 'partA09', label: '7 - L\'attente de la sortie'},
        {id: 'partA10', label: '8 - La sortie'},
        {id: 'partA11', label: '9 - Au domicile'},
        {id: 'partA12', label: '10 - La consultation post-opératoire'},
      ],
      key_source: [],
      key_all: [],
      key_target_preserve: {
        partA01: JSON.parse(localStorage.getItem('expat.partA.partA01.preserve')) || [],
        partA02: JSON.parse(localStorage.getItem('expat.partA.partA02.preserve')) || [],
        partA03: JSON.parse(localStorage.getItem('expat.partA.partA03.preserve')) || [],
        partA04: JSON.parse(localStorage.getItem('expat.partA.partA04.preserve')) || [],
        partA05: JSON.parse(localStorage.getItem('expat.partA.partA05.preserve')) || [],
        partA06: JSON.parse(localStorage.getItem('expat.partA.partA06.preserve')) || [],
        partA07: JSON.parse(localStorage.getItem('expat.partA.partA07.preserve')) || [],
        partA08: JSON.parse(localStorage.getItem('expat.partA.partA08.preserve')) || [],
        partA09: JSON.parse(localStorage.getItem('expat.partA.partA09.preserve')) || [],
        partA10: JSON.parse(localStorage.getItem('expat.partA.partA10.preserve')) || [],
        partA11: JSON.parse(localStorage.getItem('expat.partA.partA11.preserve')) || [],
        partA12: JSON.parse(localStorage.getItem('expat.partA.partA12.preserve')) || [],
      },
      key_target_improve: {
        partA01: JSON.parse(localStorage.getItem('expat.partA.partA01.improve')) || [],
        partA02: JSON.parse(localStorage.getItem('expat.partA.partA02.improve')) || [],
        partA03: JSON.parse(localStorage.getItem('expat.partA.partA03.improve')) || [],
        partA04: JSON.parse(localStorage.getItem('expat.partA.partA04.improve')) || [],
        partA05: JSON.parse(localStorage.getItem('expat.partA.partA05.improve')) || [],
        partA06: JSON.parse(localStorage.getItem('expat.partA.partA06.improve')) || [],
        partA07: JSON.parse(localStorage.getItem('expat.partA.partA07.improve')) || [],
        partA08: JSON.parse(localStorage.getItem('expat.partA.partA08.improve')) || [],
        partA09: JSON.parse(localStorage.getItem('expat.partA.partA09.improve')) || [],
        partA10: JSON.parse(localStorage.getItem('expat.partA.partA10.improve')) || [],
        partA11: JSON.parse(localStorage.getItem('expat.partA.partA11.improve')) || [],
        partA12: JSON.parse(localStorage.getItem('expat.partA.partA12.improve')) || [],
      },
      key_panel_preserve: {},
      key_panel_improve: {}
    }
  },
  computed: {
    score_preserve: function () {
      return keywords_check_preserves(this.key_target_preserve);
    },
    score_improve: function () {
      return keywords_check_improves(this.key_target_improve);
    }
  },
  methods: {
    nextsubpart() {
      console.log('Moving to next part');
      this.$emit('nextsubpart');
    }
  },
  mounted() {

    console.log('Computing keywords');
    // this.key_source = [...keyword_list];
    this.key_source = JSON.parse(JSON.stringify(keyword_list));

    // this.key_source[0].catkeywords.push(new Date().toString());

    // console.log('Keysource:', this.key_source, keyword_list);

    for (const cat_idx in this.key_source) {
      let cat = this.key_source[cat_idx];
      for (const key_idx in cat.catkeywords) {
        let id = 'K_' + cat.catref + '_' + key_idx;
        cat.catkeywords[key_idx] = {
          label: cat.catkeywords[key_idx].label || cat.catkeywords[key_idx],
          id: id,
          wordclass: 'key_' + cat.catref,
          selected: false,
          label_out: cat.label_out,
          picto: cat.picto,
        }
        this.key_all[id] = cat.catkeywords[key_idx];
      }
    }

    this.key_panel_preserve = {
      partA01: [this.key_all['K_attitudeprofessionnels_0'], this.key_all['K_priseencharge_2']],
      partA02: [],
      partA03: [this.key_all['K_infoexpldoc_0']],
      partA04: [this.key_all['K_infoexpldoc_1'], this.key_all['K_infoexpldoc_0']],
      partA05: [this.key_all['K_infoexpldoc_0'], this.key_all['K_attitudeprofessionnels_2']],
      partA06: [],
      partA07: [this.key_all['K_attitudeprofessionnels_0'], this.key_all['K_infoexpldoc_0'], this.key_all['K_priseencharge_3']],
      partA08: [this.key_all['K_priseencharge_3']],
      partA09: [this.key_all['K_attitudeprofessionnels_0'], this.key_all['K_organisation_2']],
      partA10: [this.key_all['K_organisation_2'], this.key_all['K_attitudeprofessionnels_0'], this.key_all['K_infoexpldoc_0']],
      partA11: [],
      partA12: [this.key_all['K_infoexpldoc_0']],
    };
    this.key_panel_improve = {
      partA01: [this.key_all['K_infra_1']],
      partA02: [],
      partA03: [this.key_all['K_attitudeprofessionnels_3']],
      partA04: [],
      partA05: [this.key_all['K_infra_1'], this.key_all['K_organisation_0'], this.key_all['K_priseencharge_2']],
      partA06: [this.key_all['K_infra_1'], this.key_all['K_organisation_2'], this.key_all['K_infoexpldoc_1']],
      partA07: [this.key_all['K_infra_0'], this.key_all['K_organisation_0']],
      partA08: [this.key_all['K_priseencharge_0'], this.key_all['K_infoexpldoc_1'], this.key_all['K_attitudeprofessionnels_3']],
      partA09: [],
      partA10: [this.key_all['K_infra_1'], this.key_all['K_priseencharge_3']],
      partA11: [this.key_all['K_priseencharge_3']],
      partA12: [this.key_all['K_attitudeprofessionnels_1']],
    }
  }
}
</script>


<style>

/*#all_steps H4 {
  text-align: center;
}*/

#step_list {
  margin-left: 20px;
}

#step_list, #cartrige_list {
  width: 45%;
  display: inline-block;
  vertical-align: middle;
}

#step_list .step_name {
  padding: 5px;
  font-size: 120%;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid white;
}

#step_list .step_name.step_selected {
  color: #415a8d;
  border-bottom: 1px dashed #415a8d;
}

.cart_overhead {
  position: relative;
  top: -20px;
  border: 1px solid #415a8d;
  border-radius: 3px;
  background-color: #849ac7;
  padding: 6px;
  font-size: 13px;
  font-weight: bold;
}


.one_step {
  display: grid;
  grid-template-columns: 1fr 1fr /*20px 1fr 1fr*/;
  grid-gap: 10px;
  /*margin-top: 40px;*/
}


.one_step > DIV {
  padding: 15px;
}

.one_step DIV {
  color: white;
  text-align: center;
  position: relative;
  font-size: 18px;
}

.preserve {
  background-color: #29acf2;
}

.improve {
  background-color: #5cdcdd;
  border: 1px solid #29acf2;;
}

.improve DIV {
  color: #1b2c4f;
}

.cart_underhead {
  display: grid;
  /*grid-template-columns: repeat(2, 1fr);*/
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-top: 40px;
}

.cart_underhead DIV {
  text-align: center;
}

.cart_underhead SPAN {
  color: white;
  padding: 10px;
  text-align: center;
  position: relative;
  top: -45px;
  border: 1px solid #1b2c4f;
  border-radius: 30px;
  background-color: #486dbb;
  font-size: 13px;
  font-weight: bold;
}

.score_div {
  margin: 50px;
  text-align: center;
  font-size: 120%
}
</style>