<template>
  <subpart-a>
    <h2 class="white_title">I. Le parcours amont</h2>
    <h3 class="white_title">La consultation de chirurgie</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>...Cela fait une bonne demi-heure que je suis assis lorsque mon nom s’affiche. La salle de consultation est
        dans le couloir de sortie. La porte est ouverte, je frappe, pas de réponse. Je ne me suis pas trompé, le nom du
        chirurgien est sur la porte. Des gens circulent dans le couloir mais personne ne s’adresse à moi ...sauf pour me
        demander où se trouvent les toilettes !</p><p>
        Au bout d’un bon ¼ d’heure la porte d’à côté s’ouvre. Deux personnes en sortent et se disent au revoir. Celle
        qui reste se tourne alors vers moi. Je n’arrive pas à lire son nom sur le badge qu’elle porte, trop petit. Elle
        se présente. Bonjour, veuillez entrer et vous asseoir. Que puis-je pour vous ? Je lui donne la lettre du
        rhumatologue ainsi que le compte-rendu et les résultats d’examens. Elle m’ausculte et me confirme qu’il faut
        intervenir. Elle m’explique en quoi consiste l’intervention, m’informe qu’elle sera réalisée en ambulatoire,
        qu’il suffit d’une anesthésie locorégionale, que c’est une intervention très courte et que je serai soulagé
        quasi immédiatement. Pour la sensibilité, elle mettra un peu plus de temps pour redevenir normale.</p>
      <p>
        Elle me propose de m’inclure dans son programme du mardi en quinze, ce que j’accepte. Elle me demande alors de
        me rendre au secrétariat en face de l’accueil des consultations d’orthopédie pour prendre le rendez-vous
        d’anesthésie et recevoir le dossier ambulatoire que je devrai remplir pour réaliser la pré-admission.</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA03",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA03.png');
}

/*@media screen and (max-width: 1200px) {*/
/*  .subpart_header {*/
/*    background-size: 110%;*/
/*    background-position: 20% 0;*/
/*  }*/
/*}*/

/*@media screen and (max-width: 1000px) {*/
/*  .subpart_header {*/
/*    background-position: 15% top;*/
/*    padding-bottom: 30%;*/
/*  }*/
/*}*/

/*@media screen and (max-width: 600px) {*/
/*  .subpart_header {*/
/*    background-position: 0 50%;*/
/*    padding-bottom: 30%;*/
/*    background-size: 120%;*/
/*  }*/
/*}*/

</style>