<template>
  <subpart-a>
    <h2 class="white_title">II. Le parcours en Hôpital de jour</h2>
    <h3 class="white_title">L'attente de la sortie</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>De retour dans la chambre, le brancardier m’aide à me réinstaller sur mon lit. Il place une barrière du côté
        gauche pour « éviter que mon bras tombe » et me dit qu’il faut patienter environ 2 heures avant que l’on
        m’autorise à sortir, le temps que l’effet de l’anesthésie se dissipe. Il me souhaite une bonne journée et s’en
        va.</p>
      <p>
        Mon voisin me demande comment ça s’est passé... bien, pour lui aussi, on échange encore quelques mots ... et
        l’attente commence. Une dame m’apporte une collation et m’aide à m’asseoir pour que je puisse me restaurer. Elle
        me dit qu’elle reviendra chercher le plateau plus tard. Rien mangé depuis minuit voilà qui est bienvenu.</p>
      <p>
        Une fois terminé, pour m’allonger c’est un peu compliqué, toujours cette désagréable impression d’avoir le bras
        gauche en bois, un peu d’appréhension aussi. Voilà c’est fait ... rien pour s’occuper, mais bon ... autant
        somnoler car je me suis levé tôt.</p>
      <p>
        La sensation de lourdeur dans le bras s’estompe ; j’ai un gros pansement à la main gauche, ne ressens pas de
        douleur. Les 2 heures se sont écoulées, mon voisin est parti depuis une bonne demi-heure. L’infirmier qui m’a
        pris en charge me demande comment je vais ... bien ... Il ouvre pour moi le casier ou sont mes vêtements et mes
        lunettes. Une fois rhabillé je devrai faire ma sortie dans le bureau au bout du couloir. Il est un peu plus de
        midi, j’appelle mon épouse, qui doit impérativement venir me chercher, pour qu’elle se mette en route.</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA09",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA09.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*

@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}
*/


</style>