<template>
  <!--  <h1>Première Partie : L'Écoute</h1>-->
  <div class="subpart">
    <subpartABD v-if="subpart_ === 'partABD'" subpart="partABD" :key="subpart_" @nextsubpart="nextsubpart"/>


    <subpartA01 v-if="subpart_ === 'partA01'" subpart="partA01" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA02 v-if="subpart_ === 'partA02'" subpart="partA02" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA03 v-if="subpart_ === 'partA03'" subpart="partA03" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA04 v-if="subpart_ === 'partA04'" subpart="partA04" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA05 v-if="subpart_ === 'partA05'" subpart="partA05" :key="subpart_" @nextsubpart="nextsubpart"/>

    <subpartAR1 v-if="subpart_ === 'partAR1'" subpart="partAR1" :key="subpart_" @nextsubpart="nextsubpart"/>


    <subpartA06 v-if="subpart_ === 'partA06'" subpart="partA06" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA07 v-if="subpart_ === 'partA07'" subpart="partA07" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA08 v-if="subpart_ === 'partA08'" subpart="partA08" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA09 v-if="subpart_ === 'partA09'" subpart="partA09" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA10 v-if="subpart_ === 'partA10'" subpart="partA10" :key="subpart_" @nextsubpart="nextsubpart"/>

    <subpartAR2 v-if="subpart_ === 'partAR2'" subpart="partAR2" :key="subpart_" @nextsubpart="nextsubpart"/>

    <subpartA11 v-if="subpart_ === 'partA11'" subpart="partA11" :key="subpart_" @nextsubpart="nextsubpart"/>
    <subpartA12 v-if="subpart_ === 'partA12'" subpart="partA12" :key="subpart_" @nextsubpart="nextsubpart"/>

    <subpartAR3 v-if="subpart_ === 'partAR3'" subpart="partAR3" :key="subpart_" @nextsubpart="nextsubpart"/>

    <subpartAR4 v-if="subpart_ === 'partAR4'" subpart="partAR4" :key="subpart_" @nextsubpart="nextsubpart"/>


    <div class="summary summary_partA" v-if="subpart_ === 'progress'" :key="subpart_">
      <h2>Votre progression</h2>
      <!--      <h3>Introduction</h3>-->
      <!--      <p>La règle du jeu</p>-->
      <router-link to="/about">La règle du jeu</router-link>
      <router-link to="/partA/partABD">L'accueil de Gilles B. en images</router-link>
<!--      <router-link :class="{done: progression.partA01.done}" to="/partA/partA01">Première démonstration : l’arrivée-->
<!--      </router-link>-->
      <router-link :class="{done: progression.partA02.done}" to="/partA/partA02">Démonstration : la salle
        d’attente
      </router-link>


      <h3>I. Le parcours amont</h3>
      <router-link :class="{done: progression.partA03.done}" to="/partA/partA03">1 - La consultation de chirurgie
      </router-link>
      <router-link :class="{done: progression.partA04.done}" to="/partA/partA04">2 - Pré-admission</router-link>
      <router-link :class="{done: progression.partA05.done}" to="/partA/partA05">3 - Consultation d'anesthésie
      </router-link>
      <router-link :class="{done: progression.partAR1.done}" to="/partA/partAR1">↳ Premier récapitulatif</router-link>

      <h3>II. Le parcours en Hôpital de jour</h3>
      <router-link :class="{done: progression.partA06.done}" to="/partA/partA06">4 - Le jour J</router-link>
      <router-link :class="{done: progression.partA06.done}" to="/partA/partA07">5 - La préparation</router-link>
      <router-link :class="{done: progression.partA06.done}" to="/partA/partA08">6 - L'intervention</router-link>
      <router-link :class="{done: progression.partA07.done}" to="/partA/partA09">7 - L'attente de la sortie
      </router-link>
      <router-link :class="{done: progression.partA08.done}" to="/partA/partA10">8 - La sortie</router-link>
      <router-link :class="{done: progression.partAR2.done}" to="/partA/partAR2">↳ Second récapitulatif</router-link>

      <h3>III. Le parcours aval</h3>
      <router-link :class="{done: progression.partA09.done}" to="/partA/partA11">9 - Au domicile</router-link>
      <router-link :class="{done: progression.partA10.done}" to="/partA/partA12">10 - La consultation post-opératoire
      </router-link>
      <!--      <router-link :class="{done: progression.partAR3.done}" to="/partA/partAR3">Troisième récapitulatif</router-link>-->

<!--      <router-link :class="{done: progression.partAR4.done}" to="/partA/partAR4">Récapitulatif général</router-link>-->
    </div>

    <div id="veil" v-if="veiled"></div>
  </div>

</template>

<script>
import subpartABD from '../components/subpartABD.vue';
import subpartA01 from '../components/subpartA01.vue';
import subpartA02 from '../components/subpartA02.vue';
import subpartA03 from '../components/subpartA03.vue';
import subpartA04 from '../components/subpartA04.vue';
import subpartA05 from '../components/subpartA05.vue';
import subpartAR1 from '../components/subpartAR1.vue';
import subpartA06 from '../components/subpartA06.vue';
import subpartA07 from '../components/subpartA07.vue';
import subpartA08 from '../components/subpartA08.vue';
import subpartA09 from '../components/subpartA09.vue';
import subpartA10 from '../components/subpartA10.vue';
import subpartAR2 from '../components/subpartAR2.vue';
import subpartA11 from '../components/subpartA11.vue';
import subpartA12 from '../components/subpartA12.vue';
import subpartAR3 from '../components/subpartAR3.vue';
import subpartAR4 from '../components/subpartAR4.vue';

// import Vue from "vue";

// const subparts = [
//   'subpartABD',
//   'partA01', 'partA02',
//   '/about/partRA20',
//   'partA03', 'partA04', 'partA05', 'partAR1',
//   '/about/partRA30',
//   'partA06', 'partA07', 'partA08', 'partA09', 'partA10', 'partAR2',
//   '/about/partRA40',
//   'partA11', 'partA12', 'partAR3',
//   '/about/conclusionA'
// ];

export default {
  name: "partA",
  props: ['subpart'],
  data() {
    return {
      subpart_: this.subpart,
      veiled: false,
    }
  },
  methods: {
    changestage() {
      console.log('changestage?!?');
    },
    nextsubpart() {
      this.veiled = false;
      console.log('sending nextsubpart from partA');
      this.$emit('nextsubpart', '/partA/' + this.subpart_);

      /*console.log('receiving nextsubpart');
      const idx = subparts.indexOf(this.subpart_);
      console.log('Next subpart ' + idx, this.subpart_);
      if ((idx >= 0) && (idx < subparts.length - 1)) {
        this.progression[this.subpart_].done = true;
        this.$store.dispatch('saveProgression');
        // window.location = '/partA/' + subparts[idx + 1];
        // this.$router.push('/partA/' + subparts[idx + 1]);
        let url = subparts[idx + 1];
        if (!url.startsWith('/')) {
          url = '/' + this.$options.name + '/' + url;
        }
        this.$router.push(url);
      }*/
      /*if (idx === subparts.length - 1) {
        this.$router.push('/partB/partB01');
      }*/
    },
  },
  components: {
    subpartABD,
    subpartA01,
    subpartA02,
    subpartA03,
    subpartA04,
    subpartA05,
    subpartAR1,
    subpartA06,
    subpartA07,
    subpartA08,
    subpartA09,
    subpartA10,
    subpartAR2,
    subpartA11,
    subpartA12,
    subpartAR3,
    subpartAR4,
  },
  created() {
    // console.log('Preloading images');
    this.$watch(
        () => this.$route,
        () => {
          // console.log('Parent watched route', this.subpart, this.subpart_, this.$route);
          this.subpart_ = this.$route.params.subpart;
          // this.$emit('reloadkeywords');
          // console.log('↳ children:', this.$refs);
        },
        {immediate: true}
    );
    // console.log('watching route', this.subpart, this.subpart_);
  },
  /*beforeRouteUpdate(to, from) {

    console.log('beforeRouteUpdate parent ', from, to);
    // this.loadKeywords(this.subpart);
  },
  // watch: {
  //   // call method if the route changes
  //   '$route': 'reInitialize'
  // },
  /*beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.

    // const id = to.params.id
    // this.AJAXRequest(id)
    next()
  },*/
  emits: ['nextsubpart'],
  computed: {
    progression() {
      return this.$store.state.parts;
    }
  },
  /*setup() {
    const {ref} = Vue;
    const partA01 = ref(null);

    return {partA01};
  }*/
}
</script>

<style scoped>

/*@media screen and (min-width: 1300px) {
  H2 {
    text-align: center;
  }

  .subpart {
    width: 1300px;
    margin: auto;
  }

}*/

.summary {
  margin-left: 30px;
}

.summary_partA {
}

.summary A {
  text-decoration: none;
  display: block;
  margin: 5px;
}

.summary .done {
  color: green;
}

.summary .done:before {
  content: '✓ ';
  color: green;
}

H1 {
  float: right;
  font-size: 120%;
  font-family: 'Indie Flower', cursive;
}
</style>