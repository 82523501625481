<template>
  <div :class='["subpart_div", stage]'>
    <slot/>

    <div v-if="stage == 'rule'">
      <img src="/img/rule15.png" />
      <button @click="stage = 'analysis'">Fermer</button>
    </div>


    <div class="sentence_stage sentence_read expert_on_the_left">
      <p class="bulled">Si vous voulez écouter le récit, cliquez sur
        <button @click="changeStage('listen')">Écouter</button>
      </p>
      <p class="bulled">Une fois le récit terminé, cliquez sur
        <button @click="changeStage('analysis')">Suivant</button>
        pour l'analyser
      </p>
    </div>

    <div class="sentence_stage sentence_analysis expert_on_the_left">
      Sélectionnez les mots-clés correspondant aux éléments du récit qui ont retenu votre attention
      et faites-les glisser, selon le cas, dans une des deux catégories "<span class="emphase">À&nbsp;préserver</span>"
      et "<span class="emphase">À&nbsp;améliorer</span>".
      <!--, puis cliquez sur&ndash;&gt;-->
      <!--        <button class="btn_report" @click="changeStage('report')">suivant</button>-->
      <!--        . Vous pouvez aussi-->
      <!--        <button class="btn_listen" @click="changeStage('listen')">ré-écouter</button>-->
    </div>

    <div class="sentence_stage sentence_report expert_on_the_left">
      <p>Vous pouvez maintenant comparer vos <span class="emphase">choix</span> avec ceux validés par Gilles lors de la
        <span class="emphase">reformulation</span>.</p>
    </div>

    <hr v-if="stage != 'rule'" />

    <keywords :subpart="subpart" :stage="stage"/>

    <div class="sentence_stage sentence_listen">
<!--      <av-waveform class="sentence_listen_player" controlsList="nodownload" ref="player"-->
<!--                   :audio-src="audioPath"-->
<!--      ></av-waveform>-->

<!--      <hr/>-->

      <div class="expert_on_the_left">
        Utilisez le lecteur ci-dessous pour écouter le récit. Si vous ne pouvez pas l'écouter, cliquez sur <span
          class="emphase">texte</span>
        pour l'afficher sous forme de texte. Une fois le récit terminé, cliquez sur <span class="emphase">suivant</span>
        pour l'analyser.
      </div>
    </div>

    <div class="sentence_stage sentence_next ">
      <hr/>
      <div class="expert_on_the_left">
        <p class="bulled">
          Pour passer à la prochaine étape, cliquez sur <span class="emphase">suivant</span>.
        </p>
      </div>
    </div>

    <div class="sentence_stage sentence_analysis">
      <hr/>
      <div class="expert_on_the_left">
        <p class="bulled">
          Pour enregistrer vos choix et les comparer à ceux validés par Gilles, cliquez sur <span
            class="emphase">suivant</span>.
        </p>
      </div>
    </div>

    <hr/>

    <div class="bottom_buttons">
      <div class="btn_rule" v-if="stage == 'analysis'" style="float: left">
        <button class="big" @click="stage = 'rule'">Règle du jeu</button>
      </div>
      <div class="sentence_stage sentence_listen" style="float: left">
        <button class="big" @click="changeStage('reading')" id="listen_to_text">Texte</button>
      </div>

      <av-waveform v-if="stage != 'report'" class="sentence_listen_player" controlsList="nodownload" ref="player" style="display: inline-block"
                   :audio-src="audioPath"
      ></av-waveform>

      <div class="sentence_stage sentence_listen" style="float: right">
<!--        <button class="big" @click="changeStage('reading')" id="listen_to_text">Texte</button>-->
        <button class="big btn_go_to_analyze" @click="changeStage('analysis')">Suivant</button>
      </div>

      <div class="sentence_stage sentence_analysis" style="float: right">
<!--        <button class="big" @click="changeStage('listen')">Ré-écouter</button>-->
        <button class="big" @click="changeStage('report')">Suivant</button>
      </div>

      <div class="sentence_stage sentence_next" style="float: right">
        <button class="big" @click="nextsubpart()">Suivant</button>
      </div>
    </div>

  </div>

</template>

<script>
import keywords from './keywords.vue';
import {mapGetters} from "vuex";

export default {
  name: "subpartA",
  data() {
    return {
      stage: 'listen'
    }
  },
  components: {
    keywords
  },
  methods: {
    changeStage(newStage) {
      this.stage = newStage;

      console.log('changeState', this.$refs.player, this.$refs.player.audioSrc);

      // this.$refs.player.audioSrc = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
      const players = document.getElementById("app").getElementsByTagName("audio");
      for (let i = 0; i < players.length; i++) {
        console.log(players[i]);
        players[i].pause();
        players[i].currentTime = 0;
        players[i].src = 'data:audio/wav;base64,UklGRiQAAABXQVZFZm10IBAAAAABAAEAVFYAAFRWAAABAAgAZGF0YQAAAAA=';
      }

      if (this.$parent.changeStage) {
        this.$parent.changeStage(newStage);
      }
    },
    nextsubpart() {
      this.$parent.veiled = false;
      console.log('sending nextsubpart from subpartA');
      this.$emit('nextsubpart');
    }
  },
  computed: {
    audioPath() {
      return '../audio/' + this.subpart + '.mp3';
    },
    ...mapGetters([
      'getSubpart',
    ])
  },
  mounted() {
    const done = this.getSubpart('partA.' + this.subpart + '.done');
    console.log('done is ', done);
    if (done) {
      this.changeStage('report');
    }
  },
  props: ['subpart'],
  emits: ['changeStage', 'nextsubpart'],
}
</script>

<style>


.subpart .analysis .subpart_header, .subpart .reading .subpart_header {
  padding-bottom: 0;
  height: 170px;
}

.reading .speakable {
  display: block;
  background-color: #fcf6db;
  padding: 10px;
}

.subpart_div.reading P {
  margin: 0;
  padding: 15px;
}

.subpart_div.reading .speakable P {
  margin-left: 30px;
}


.sentence_stage {
  display: none;
}

#keywords_div, .sentence_next, .speakable, .sentence_read, .sentence_report, .sentence_listen {
  display: none;
}

.listen .sentence_listen {
  display: block;
}

.sentence_listen_player {
  display: inline-block;
  margin: auto;
}

.analysis #keywords_div, .report #keywords_div, .analysis .sentence_analysis {
  display: block;
}

.report .sentence_next, .report .sentence_report {
  display: block;
}

.reading .sentence_read {
  display: block;
}

/*@media screen and (min-width: 1000px) {
  .subpart_div {
    margin-bottom: 20px;
    margin-right: 270px;
  }
}*/

.speakable P {
  text-align: justify;
}

/*.speakable:before {*/
/*  content: '🔊 ';*/
/*}*/

.sentence_next {
  /*text-align: justify;*/
  /*font-style: italic;*/
}



</style>