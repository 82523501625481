<template>
  <div class="subpart_div">
    <h2 class="white_title">I. Le parcours amont</h2>
    <h3 class="white_title">Score d'écoute</h3>
    <subpartAR :part_list="['A03', 'A04', 'A05']" subpart="partAR1" @nextsubpart="nextsubpart" />
  </div>
</template>

<script>

import subpartAR from "@/components/subpartAR";

export default {
  name: "subpartAR1",
  components: {
    subpartAR
  },
  methods: {
    nextsubpart() {
      console.log('sending nextsubpart from subpartAR');
      this.$emit('nextsubpart');
    },
  },
  emits: ['nextsubpart'],
}
</script>


<style>

</style>