<template>
  <template v-for="keyword in keywords" :key="keyword.id">
    <div class="draggable_keyword">
      <span :class="['keyword_picto', keyword.wordclass]"
            :data-wordid="keyword.id" :data-wordclass="keyword.wordclass"
      >{{ keyword.picto }}</span>{{ keyword.label }}
    </div>
  </template>
</template>

<script>
export default {
  name: "keywordListDisplay",
  props: ['keywords']
}
</script>

<style>

/* .keyword_picto {
   font-size: 70%;
   border-radius: 5px;
   border: 1px solid grey;
   padding: 2px;
   margin-right: 5px;
   width: 5px;
   display: inline-block;
   text-align: center;
 }*/
</style>