<template>

  <div class="expert_on_the_left">Voici votre <span class="emphase">score d'écoute</span> sur l'ensemble de ce parcours.
  </div>

  <hr/>

  <div :class="['listening_report', listening_rating]">
    <img :src="'/img/ecoute_' + listening_rating + '.png'"/>
    <div class="listening_score">
      {{ listening_score }}<br/>%
    </div>
  </div>

  <hr/>

  <div class="expert_on_the_left">Pour passer au prochain parcours, cliquez sur <span class="emphase">suivant</span>.
  </div>

  <hr/>


  <div class="bottom_buttons" style="text-align: right">
    <button class="big" @click="nextsubpart()">Suivant</button>
  </div>

</template>

<script>
// import keywordListDisplay from './keywordListDisplay.vue';
import {keywords_check_step} from '../utils';
import { mapGetters } from 'vuex';

export default {
  name: "subpartAR",
  components: {
    // keywordListDisplay
  },
  data() {
    return {
      // key_target_preserve: this.key_target_list('preserve'),
      // key_target_improve: this.key_target_list('improve'),
    }
  },
  methods: {
    nextsubpart() {
      console.log('Moving to next part');
      this.$emit('nextsubpart');
    },
    key_target_list: function (variant) {
      let list = {};
      for (const part of this.part_list) {
        // list['part' + part] = JSON.parse(localStorage.getItem('expat.partA.part' + part + '.' + variant)) || []
        console.log('key_target_list', this.$store.parts);
        list['part' + part] = this.getSubpart('partA.part' + part + '.' + variant);
      }
      return list;
    },
  },
  computed: {
    key_target_preserve: function () {
      return this.key_target_list('preserve')
    },
    key_target_improve: function () {
      return this.key_target_list('improve')
    },
    listening_score: function () {
      const score = keywords_check_step(this.key_target_preserve, this.key_target_improve, this.part_list);

      console.log('Score ' + this.subpart + ' : ' + score);

      this.$store.dispatch('saveProgression', {path: 'partA.' + this.subpart + '.score', data: score});
      this.$store.dispatch('sendProgression');

      return score;
    },
    listening_rating: function () {
      let score = this.listening_score;
      return score < 50 ? 'mauvais' : score < 80 ? 'moyen' : 'bon';
    },
    ...mapGetters([
      'getSubpart',
    ])
  },
  emits: ['nextsubpart'],
  props: {
    'subpart': String,
    'part_list': {
      type: Array, default: function () {
        return ['A03', 'A04', 'A05']
      },
    }
  }
}
</script>


<style>

.listening_score {
  position: absolute;
  left: 8%;
  top: 24%;
  text-align: center;
  font-size: 6vw;
  color: white;
  font-weight: 600;
  width: 13%;
  line-height: 0.9;
  /*outline: 1px solid blue;*/
}

@media screen and (min-width: 1000px) {
  .listening_score {
    font-size: 60px;
  }
}

.listening_report {
  background-size: cover;
  /*aspect-ratio: 1316 / 504;*/
  position: relative;
}

/*.listening_report.mauvais {
  background-image: url("../../public/img/ecoute_mauvais.png");
}

.listening_report.moyen {
  background-image: url("../../public/img/ecoute_moyen.png");
}

.listening_report.bon {
  background-image: url("../../public/img/ecoute_bon.png");
}
*/
</style>