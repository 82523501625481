<template>
  <subpartA>
    <h2 class="white_title">Première démonstration</h2>
    <h3 class="white_title">Arrivée à l'hôpital pour la consultation de chirurgie</h3>
    <hr />
    <div class="subpart_header">
      <!-- placeholder for the drawing -->
    </div>
    <div class="speakable">
      <p>J’aimerai vous parler tout d’abord de mon premier contact avec votre établissement.</p>
      <p>
        J’ai pris rendez-vous avec le chirurgien par téléphone. Sa secrétaire est particulièrement aimable. En raison de
        l’urgence elle m’a proposé un rendez-vous dans une semaine en me prévenant que le chirurgien me recevrait entre
        deux patients et m’a recommandé de ne pas oublier d’apporter mes examens et la lettre du rhumatologue.</p>
      <p>
        Une semaine plus tard j’arrive à l’hôpital avec 30 minutes d’avance. Je me présente à l’accueil, j’accède
        rapidement à une hôtesse, donne mon nom et mon heure de rendez-vous. L’hôtesse m’informe que je dois tout
        d’abord aller au bureau situé dans le couloir menant à la salle d’attente des consultations.</p>
    </div>
    <div>
      <v-tour name="tour_1" :steps="tour_steps_1" :options="tour_options" :callbacks="tour_cb_1"></v-tour>
      <v-tour name="tour_2" :steps="tour_steps_2" :options="tour_options" :callbacks="tour_cb_2"></v-tour>
    </div>
  </subpartA>

</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA01",
  components: {
    subpartA,
  },
  methods: {
    changeStage(newStage) {
      console.log('changeStage', newStage);
      this.$parent.veiled = true;
      this.$tours['tour_2'].start();
    }
  },
  data() {
    return {
      tour_options: {
        labels: {
          buttonSkip: 'Passer les explications',
          buttonPrevious: 'Précédent',
          buttonNext: 'Suivant',
          buttonStop: 'Terminer'
        },
        highlight: true
      },
      tour_cb_1: {
        onFinish: () => this.$parent.veiled = false,
        onSkip: () => this.$emit('nextsubpart'),
      },
      tour_cb_2: {
        onFinish: () => this.$emit('nextsubpart'),
        onSkip: () => this.$emit('nextsubpart'),
      },
      tour_steps_1: [
        {
          target: 'H3',
          header: {
            title: 'Bienvenue ! Nous allons vous présenter pas-à-pas le déroulement du jeu',
          },
          content: 'Le récit est découpé en plusieurs séquences que vous allez étudier l\'une après l\'autre'
        },
        {
          target: '.sentence_listen_player',
          content: 'Le lecteur audio vous permet d\'écouter le récit ; vous pouvez le mettre en pause ou changer le volume sonore',
          params: {
            placement: 'top'
          }
        },
        {
          target: '.btn_go_to_analyze',
          content: 'Lorsque vous avez fini d\'écouter, cliquez sur Suivant pour passer à l\'analyse du texte',
          params: {
            placement: 'top'
          }
        },
      ],
      tour_steps_2: [
        {
          target: '.keyword_sources_div',
          content: `Voici la liste des mots-clés possibles : vous devez sélectionner ici ceux que vous avez repérés dans le récit`,
          params: {
            placement: 'top'
          }
        },
        {
          target: '#key_target_preserve',
          content: 'Vous devez déposer ici les mot-clés correspond aux sujets qui vous paraissent convenir. Dans ce premier exemple, deux mots-clés sont déjà présents',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#key_target_improve',
          content: 'Vous devez déposer ici les mot-clés correspond aux sujets qui doivent être améliorés. Dans ce premier exemple, un mots-clé est déjà présent',
          params: {
            placement: 'top'
          }
        },
        {
          target: '.btn_listen',
          content: 'Vous pouvez ré-écouter le récit si vous avez un doute',
        },
        {
          target: '.btn_nextsubpart',
          content: 'Lorsque vous avez fini, vous pouvez passer à la séquence suivante',
        },
      ]
    }
  },
  mounted: function () {
    this.$parent.veiled = true;
    this.$tours['tour_1'].start();
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA01.png');
  /*padding-bottom: 45%;*/
}

/*@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 135%;
    background-position: 20% top;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: 15% top;
    padding-bottom: 30%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: 20% top;
    padding-bottom: 30%;
    background-size: 180%;
  }
}*/

</style>