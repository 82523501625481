<template>
  <subpartA ref="subpartA">
    <h2 class="white_title">L'Écoute</h2>
    <h3 class="white_title">Démonstration</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>Après avoir hésité, je me dirige vers la seule porte visible. Rien n’indique apparemment ce qu’on y fait. Je
        frappe et entre. La personne qui me reçoit, vérifie mon rendez-vous et me demande carte vitale et attestation de
        mutuelle. Après les avoir enregistrées et rendues, elle me remercie et m’invite à aller en salle d’attente me
        présenter à l’accueil de la consultation d’orthopédie.</p>
      <p>
        Pas facile à trouver l’accueil des consultations d’orthopédie. On lui tourne le dos entrant. Je me présente, la
        secrétaire enregistre mon arrivée sur son ordinateur et me propose d’aller m’asseoir. Mon nom s’affichera sur
        les écrans avec le n° du bureau de consultation.</p>
      <p>
        La salle d’attente est très grande, assez bruyante. Une vingtaine de bureaux sont placés tout autour et dans le
        couloir de sortie.</p>
      <p>
        Il est presque l’heure de ma consultation, heureusement que je suis arrivé en avance. En attendant j’observe les
        mouvements autour de moi, des patients plus ou moins handicapés, des blouses blanches, des infirmiers, des
        brancardiers... Régulièrement une petite sonnerie signale l’affichage d’un nouveau nom. La salle ne désemplit
        pas. Des patients arrivés après moi sont reçus, ... c’est vrai que je suis en surnombre.</p>
    </div>
    <v-tour name="tour_1" :steps="tour_steps_1" :options="tour_options" :callbacks="tour_cb_1"></v-tour>
    <v-tour name="tour_2" :steps="tour_steps_2" :options="tour_options" :callbacks="tour_cb_2"></v-tour>
  </subpartA>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA02",
  components: {
    subpartA
  },
  methods: {
    changeStage(newStage) {
      console.log('changeStage', newStage);
      if (newStage === 'analysis') {
        this.$parent.veiled = true;
        this.$tours['tour_2'].start();
      }
    }
  },
  data() {
    return {
      tour_options: {
        labels: {
          buttonSkip: 'Passer les explications',
          buttonPrevious: 'Précédent',
          buttonNext: 'Suivant',
          buttonStop: 'Terminer'
        },
        highlight: true
      },
      tour_cb_1: {
        onFinish: () => this.$parent.veiled = false,
        onSkip: () => this.$emit('nextsubpart'),
      },
      tour_cb_2: {
        // onFinish: () => this.$emit('nextsubpart'),
        onFinish: () => { this.$parent.veiled = false; this.$refs.subpartA.stage = 'report' },
        onSkip: () => this.$emit('nextsubpart'),
      },
      tour_steps_1: [
        {
          target: 'H2',
          header: {
            title: 'Bienvenue ! Nous allons vous présenter pas-à-pas le déroulement de la première partie du jeu',
          },
          content: 'Le récit est découpé en 10 séquences que vous allez étudier successivement'
        },
        {
          target: '.sentence_listen_player',
          content: 'Le <span class="emphase">lecteur audio</span> vous permet d\'écouter le récit ; vous pouvez le mettre en pause ou changer le volume sonore',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#listen_to_text',
          content: 'Si vous ne pouvez pas écouter ou si vous préférez lire la transcription vous pouvez cliquer sur le bouton « <span class="emphase">Texte</span> »',
          params: {
            placement: 'top'
          }
        },
        {
          target: '.btn_go_to_analyze',
          content: 'Lorsque vous avez fini d\'écouter, cliquez sur Suivant pour analyser le récit',
          params: {
            placement: 'top'
          }
        },
      ],
      tour_steps_2: [
        {
          target: '.keyword_sources_div',
          content: `Dans les 6 familles ci-dessous, repérez les mots-clés correspondant aux éléments du récit qui ont retenu votre attention`,
          params: {
            placement: 'top'
          }
        },
        {
          target: '#dk_ti_K_infra_2',
          content: 'Dans l’extrait que vous venez d’écouter, Gilles B. trouve la salle bondée et bruyante, manquant de\n' +
              '<span class="emphase">confort</span>. Le mot clé approprié pour traduire ceci est « <span class="emphase">confort</span> » qui appartient à la famille\n' +
              '« <span class="emphase">Infrastructure</span> ». Il doit être placé dans la case « <span class="emphase">à améliorer</span> »',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#dk_ti_K_priseencharge_2',
          content: 'Dans la salle d’attente Gilles D. trouve que ça n’est pas <span class="emphase">rapide</span>. Le mot clé permettant de\n' +
              'traduire ceci est « <span class="emphase">rapidité</span> » qui appartient à la famille « <span class="emphase">Prise en charge</span> ». Il doit être placé dans la\n' +
              'case « <span class="emphase">à améliorer</span> »',
          params: {
            placement: 'top'
          }
        },
        {
          target: '#key_target_preserve',
          content: 'Si vous ne relevez aucun élément à préserver (ou à améliorer) dans le récit de Gilles, vous devez laisser la case vide.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '.sentence_analysis BUTTON',
          content: 'Lorsque vous avez fini, cliquez sur <span class="emphase">Suivant</span> pour comparer vos choix et ceux validés par Gilles D.',
          params: {
            placement: 'top'
          }
        },
      ]
    }
  },
  mounted: function () {
    this.$parent.veiled = true;
    this.$tours['tour_1'].start();
  }
}
</script>

<style>
@import "../../public/tour.css";

.subpart_header {
  background-image: url('../../public/img/partA02.png');
  /*background-repeat: no-repeat;*/
  /*background-size: cover;*/
  /*padding-bottom: 43%;*/
  /*border: 1px solid grey;*/
  /*background-position: left -115px;*/
}

/*@media screen and (max-width: 1200px) {
  .subpart_header {
    background-position: 0 55%;
    background-size: 120%;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: 50% 60%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: 0 20%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}*/


</style>