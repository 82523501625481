<template>
  <div id="keywords_div">
    <div class="keyword_drawers_div">


      <draggable
          id="key_target_preserve"
          :list="key_target_preserve"
          group="keywords"
          @start="onDragStart"
          @end="onDragEnd"
          @change="onKeywordChange"
          :sort="false"
          item-key="id"
          :move="onDragMove"
          :disabled="isDragDisabled"
      >
        <template #item="{element}">
          <div :class="['draggable_keyword', element.css_class]"
               @mouseover="setTopicHover(element.wordclass)"
               @mouseleave="setTopicHover()"
          >
            <span :class="['keyword_picto', element.wordclass]"
                  :data-wordid="element.id" :data-wordclass="element.wordclass"
            >{{ element.picto }}</span>
              <span class="keyword"
                    :data-wordid="element.id" :data-wordclass="element.wordclass"
              >{{ element.label }}</span>
          </div>
        </template>
        <template #header>
          <p class="target_title">À préserver</p>
          <p class="keyword_empty" v-if="key_target_preserve.length < 1">Déposez ici jusqu'à 5 éléments à préserver</p>
        </template>
      </draggable>
      <draggable
          id="key_report_preserve"
          :list="key_report_preserve"
          group="keywords"
          @start="onDragStart"
          @end="onDragEnd"
          @change="onKeywordChange"
          :sort="false"
          item-key="id"
          :move="onDragMove"
          :disabled="isDragDisabled"
      >
        <template #item="{element}">
          <div :id="'dk_rp_' + element.id"
              :class="['draggable_keyword', element.css_class]"
               @mouseover="setTopicHover(element.wordclass)"
               @mouseleave="setTopicHover()"
          >
            <span :class="['keyword_picto', element.wordclass]"
                  :data-wordid="element.id" :data-wordclass="element.wordclass"
            >{{ element.picto }}</span>
              <span class="keyword"
                    :data-wordid="element.id" :data-wordclass="element.wordclass"
              >{{ element.label }}</span>
          </div>
        </template>
        <template #header>
          <p class="target_title">À préserver (reformulation)</p>
          <p class="keyword_empty" v-if="key_report_preserve.length < 1">Déposez ici jusqu'à 5 éléments à préserver</p>
        </template>
      </draggable>
      <draggable
          id="key_target_improve"
          :list="key_target_improve"
          group="keywords"
          @start="onDragStart"
          @end="onDragEnd"
          @change="onKeywordChange"
          :sort="false"
          item-key="id"
          :move="onDragMove"
          :disabled="isDragDisabled"
      >
        <template #item="{element}">
          <div :id="'dk_ti_' + element.id"
              :class="['draggable_keyword', element.css_class]"
               @mouseover="setTopicHover(element.wordclass)"
               @mouseleave="setTopicHover()">
            <span :class="['keyword_picto', element.wordclass]"
                  :data-wordid="element.id" :data-wordclass="element.wordclass"
            >{{ element.picto }}</span>
              <span class="keyword"
                    :data-wordid="element.id" :data-wordclass="element.wordclass"
              >{{ element.label }}</span>
          </div>
        </template>
        <template #header>
          <p class="target_title">À améliorer</p>
          <p class="keyword_empty" v-if="key_target_improve.length < 1">Déposez ici jusqu'à 5 éléments à améliorer</p>
        </template>
      </draggable>
      <draggable
          id="key_report_improve"
          :list="key_report_improve"
          group="keywords"
          @start="onDragStart"
          @end="onDragEnd"
          @change="onKeywordChange"
          :sort="false"
          item-key="id"
          :move="onDragMove"
          :disabled="isDragDisabled"
      >
        <template #item="{element}">
          <div :class="['draggable_keyword', element.css_class]"
               @mouseover="setTopicHover(element.wordclass)"
               @mouseleave="setTopicHover()">
            <span :class="['keyword_picto', element.wordclass]"
                  :data-wordid="element.id" :data-wordclass="element.wordclass"
            >{{ element.picto }}</span>
              <span class="keyword"
                    :data-wordid="element.id" :data-wordclass="element.wordclass"
              >{{ element.label }}</span>
          </div>
        </template>
        <template #header>
          <p class="target_title">À améliorer (reformulation)</p>
          <p class="keyword_empty" v-if="key_report_improve.length < 1">Déposez ici jusqu'à 5 éléments à améliorer</p>
        </template>
      </draggable>

    </div>
    <hr class="middle_line"/>
    <div class="keyword_sources_div">
      <template v-for="cat in key_source" :key="cat.catref">
        <div :class="[
              'keyword_source keyword_source_div', cat.catref,
              isSourceHilited(cat.catref) ? 'hilited' : '',
              hovered_theme === 'key_' + cat.catref ? 'hovering' : ''
            ]"
        >
          <h5>{{ cat.catname }}</h5>
          <draggable
              :id="'keys_source_' + cat.catref"
              :list="cat.catkeywords"
              class="keysource_list"
              group="keywords"
              @start="onDragStart"
              @end="onDragEnd"
              :sort="false"
              item-key="id"
              @change="onKeywordChange"
              :move="onDragMove"
          >
            <template #item="{element}">
              <div class="draggable_keyword"
                   :class="{selected: selected_keyword === element.id}" :data-wordid="element.id"
              >
              <span class="keyword"
                    :data-wordclass="element.wordclass"
              >{{ element.label }}</span>
              </div>
            </template>
            <template #header v-if="cat.length < 1">
              <p class="keyword_empty">Plus rien ici !</p>
            </template>
          </draggable>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
// import draggable from 'vuedraggable';
import keyword_list from "@/keyword_list";
import draggable from "vuedraggable";
import {getPanels, keywordImportFromArray /*, storeKeywords*/} from "@/utils.js";
// import keywordDrawer from "@/components/keywordDrawer";

console.log('Const\'ing keyword_list');

console.log('Keywords loaded', keyword_list);


export default {
  name: "keywords",
  data() {
    return {
      key_source: [], // loadKeywords(this.subpart),
      keywords_index: {},

      key_target_preserve: [],
      key_report_preserve: [],
      key_target_improve: [],
      key_report_improve: [],
      selected_keyword: '',
      hovered_theme: '',
      hilited_targets: [],
      isDragDisabled: false,
      // stage: this.stage,
    }
  },
  components: {
    draggable,
    // keywordDrawer
  },
  props: ['subpart', 'stage'],
  methods: {
    loadKeywords() {
      let subpart = this.subpart;

      console.log('Reloading keywords ' + subpart);

      this.key_source = JSON.parse(JSON.stringify(keyword_list));
      this.keywords_index = {};

      for (const cat_idx in this.key_source) {
        let cat = this.key_source[cat_idx];
        keywordImportFromArray(cat);

        // indexation pour la correction
        for (const k_idx in cat.catkeywords) {
          this.keywords_index['K_' + cat.catref + '_' + k_idx] = JSON.parse(JSON.stringify(cat.catkeywords[k_idx]));
        }

        // chargement des mots clés restants disponibles en bas
        // let stored_cat = JSON.parse(localStorage.getItem('expat.partA.' + subpart + '.' + cat.catref));
        // let stored_cat = this.$store.state.parts.partA[subpart].keywords[cat.catref];
        if (this.$store.state.parts.partA[subpart].keywords && this.$store.state.parts.partA[subpart].keywords[cat.catref]) {
          cat.catkeywords = this.$store.state.parts.partA[subpart].keywords[cat.catref];
        }
      }

      if (this.subpart === 'partA02') {
        this.key_target_preserve = [
        ];
        this.key_target_improve = [
          this.key_source[5].catkeywords[2],
          this.key_source[0].catkeywords[2]
        ];
        this.key_source[0].catkeywords.splice(2, 1);
        this.key_source[5].catkeywords.splice(2, 1);
      } else {
          this.key_target_preserve = this.$store.state.parts.partA[this.subpart].preserve || [];
          this.key_target_improve = this.$store.state.parts.partA[this.subpart].improve || [];
        // this.key_target_preserve = JSON.parse(localStorage.getItem('expat.partA.' + this.subpart + '.preserve')) || [];
        // this.key_target_improve = JSON.parse(localStorage.getItem('expat.partA.' + this.subpart + '.improve')) || [];
      }

      // console.log('Reloaded keywords (' + subpart + ')');
      // return keyword_list;
    },

    checkPanelAnswers() {
      let panels = getPanels(),
          panel_preserve = panels.preserve[this.subpart.replace('part', '')],
          panel_improve = panels.improve[this.subpart.replace('part', '')];

      this.key_report_preserve = [];
      if (panel_preserve.length) {
        for (const panel_key of panel_preserve) {
          let keyword_data = this.keywords_index[panel_key];
          keyword_data.css_class = 'missing';
          for (const user_key of this.key_target_preserve) {
            if (!user_key.css_class) {
              user_key.css_class = 'wrong';
            }
            if (user_key.id === keyword_data.id) {
              user_key.css_class = 'correct';
              keyword_data.css_class = 'correct';
            }
          }
          this.key_report_preserve.push(keyword_data);
        }
      } else {
        for (const user_key of this.key_target_preserve) {
          user_key.css_class = 'wrong';
        }
      }

      this.key_report_improve = [];
      if (panel_improve.length) {
        for (const panel_key of panel_improve) {
          let keyword_data = this.keywords_index[panel_key];
          keyword_data.css_class = 'missing';
          for (const user_key of this.key_target_improve) {
            if (!user_key.css_class) {
              user_key.css_class = 'wrong';
            }
            if (user_key.id === keyword_data.id) {
              user_key.css_class = 'correct';
              keyword_data.css_class = 'correct';
            }
          }
          this.key_report_improve.push(keyword_data);
        }
      } else {
          for (const user_key of this.key_target_improve) {
            user_key.css_class = 'wrong';
          }
      }

      /*this.key_report_improve = [];
      for (const keyword_key of panel_improve) {
        this.key_report_improve.push(this.keywords_index[keyword_key]);
      }*/
      console.log('panel', this.key_report_preserve, this.key_report_improve);
    },

    isSourceHilited(catref) {
      return this.hilited_targets.indexOf(catref) >= 0;
    },
    onDragStart(evt) {
      console.log('START', evt, evt.item.__draggable_context);
      let source_cat = evt.item.__draggable_context.element.wordclass.replace('key_', '');
      console.log('start ' + source_cat, this.hilited_targets);

      if (this.hilited_targets.indexOf(source_cat) < 0) {
        this.hilited_targets.push(source_cat);
      }
    },
    onDragEnd(evt) {
      console.log('END', evt);
      this.hilited_targets = [];
      /*let source_cat = evt.draggedContext.element.wordclass.replace('key_', '');

      console.log('end ' + source_cat);

      let i = this.hilited_targets.indexOf(source_cat);
      if (i >= 0) {
        delete this.hilited_targets[i];
      }*/
      this.$store.dispatch('sendProgression');
    },
    onKeywordChange(evt) {
      console.log('CHANGE', evt)
      // storeKeywords(this.key_source, this.subpart);
      this.$store.dispatch('saveProgression', {path: this.subpart, keywords: this.key_source});
      this.$store.dispatch('saveProgression', {path: 'partA.' + this.subpart + '.preserve', data: this.key_target_preserve});
      this.$store.dispatch('saveProgression', {path: 'partA.' + this.subpart + '.improve', data: this.key_target_improve});
      // localStorage.setItem('expat.partA.' + this.subpart + '.preserve', JSON.stringify(this.key_target_preserve));
      // localStorage.setItem('expat.partA.' + this.subpart + '.improve', JSON.stringify(this.key_target_improve));
    },
    onDragMove(evt) {
      // console.log('MOVE', evt);
      let source_cat = evt.draggedContext.element.wordclass.replace('key_', '');
      // console.log('move ' + source_cat);
      if (this.hilited_targets.indexOf(source_cat) < 0) {
        this.hilited_targets.push(source_cat);
      }

      return ((evt.to.id === 'key_target_preserve') && (this.key_target_preserve.length < 5))
          || ((evt.to.id === 'key_target_improve') && (this.key_target_improve.length < 5))
          || (evt.to.id === 'keys_source_' + source_cat)
          ;
    },

    setTopicHover(theme) {
      // console.log('hover', theme);
      this.hovered_theme = theme;
    }
  },
  /*beforeRouteUpdate(to, from) {
          console.log('beforeRouteUpdate ', to, from);
          // this.loadKeywords(this.subpart);
  },*/
  mounted() {
    console.log("Keywords mounted", this.subpart);
  },
  watch: {
    stage: function (newVal, oldVal) { // watch it
      console.log('Stage changed to "' +  newVal + '" from "' + oldVal + '"');

      if (Object.keys(this.keywords_index).length === 0) {
        this.loadKeywords();
      }
      if (newVal === 'analysis') {
        this.isDragDisabled = false;
      }
      if (newVal === 'report') {
        this.checkPanelAnswers();
        this.isDragDisabled = true;

        this.$store.dispatch('saveProgression', {
          path: 'partA.' + this.subpart + '.done',
          data: true
        });
        this.$store.dispatch('sendProgression');
      }
    }
  },
  /*updated() {
    console.log("Keywords updated", this.subpart);
  },
  watch: {
    $route() {
      console.log('Keywords watched', this.subpart);
    }
  },
  /*created() {
    this.$watch(
        () => this.$route,
        () => {
          console.log('watched '+ this.subpart);
          this.loadKeywords(this.subpart);
        },
        {immediate: true}
    );
    // console.log('watching route', this.subpart, this.subpart_);
  },*/
}
</script>

<style scoped>
@import "../../public/keywords.css";



</style>