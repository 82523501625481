<template>
  <subpart-a>
    <h2 class="white_title">II. Le parcours en Hôpital de jour</h2>
    <h3 class="white_title">L'intervention</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>J’attends sans savoir quoi faire. Il s’est passé moins de 10’, le même brancardier vient me chercher. Il
        vérifie ma tenue et nous quittons la chambre pour prendre l’ascenseur et monter au bloc. Arrivés dans le sas du
        bloc, je regarde l’horloge, il est bientôt 8h30. Le sas est encombré par un fauteuil et un brancard. Il
        m’installe sur le brancard et le sas s’ouvre pour que l’on me conduise en salle d’anesthésie.</p>
      <p>
        Dans la salle qui me paraît très grande et animée, le brancardier a placé mon brancard à côté de celui d’un
        jeune homme. Nous attendons en silence. Au bout d’un moment 2 personnes s’approchent de mon voisin et lui
        annoncent qu’elles vont pratiquer une anesthésie locorégionale.</p>
      <p>
        Je ne vois pas ce qui se passe, mais j’entends ce qui se dit. J’ai l’impression que celui qui opère est guidé
        par son collègue, Il doit être en apprentissage, il me semble qu’ils sont à la recherche d’un nerf à anesthésier
        ...ça n’a pas l’air si facile que ça si j’en juge par leurs commentaires. C’est un peu stressant.</p>
      <p>
        C’est terminé, mon voisin est conduit en salle d’opération et le duo s’approche de mon brancard. L’un d’eux
        m’informe qu’ils vont pratiquer une anesthésie locorégionale et que pour cela il faut que j’étende mon bras. Ils
        vérifient mon identité et que c’est la main gauche qui doit être opérée. Je ne vois pas ce qu’ils font, mais
        cela me semble laborieux et relativement long.... C’est terminé. J’ai l’impression que mon bras gauche est un
        bout de bois pesant.</p>
      <p>
        En route pour la salle d’opération... Je suis accueilli par le chirurgien qui me dit quelques mots du genre cela
        va bien se passer. On m’installe, je suis séparé des opérateurs par une espèce de rideau, je ne vois pas ce qui
        se passe mais j’entends tout ce qui se dit ... régulation de la tension artérielle ... dialogue entre chirurgien
        et infirmière... L’intervention est courte, comme prévu, le rideau se lève, le chirurgien me dit que tout
        s’est bien passé et me donne rendez-vous dans un mois ....</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA08",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA08.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*
@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}*/

</style>