<template>
  <subpart-a>
    <h2 class="white_title">II. Le parcours en Hôpital de jour</h2>
    <h3 class="white_title">La sortie</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>Une fois dans le couloir, pas très bien éclairé, je ne suis pas très sûr de là où je dois aller. J’aperçois un
        bureau vitré dans lequel une dame est en train d’examiner des dossiers, la porte est ouverte, j’entre et me
        présente. Je suis au bon endroit. Mon interlocutrice me demande, avant toute chose, si quelqu’un vient me
        chercher car sinon je ne pourrais pas sortir. Je lui confirme la venue de mon épouse.</p>
      <p>
        Elle me remet alors le compte-rendu opératoire, l’ordonnance pour les soins infirmiers, celle pour la pharmacie
        et me rappelle les consignes contenues dans le dossier qui m’a été remis ...</p>
      <p>
        Elle programme mon rendez-vous avec le chirurgien et m’informe que je recevrai une confirmation par SMS. Elle
        m’indique qu’en cas de problème je dois appeler le service ambulatoire dont le numéro de téléphone figure dans
        mon dossier, me souhaite un bon rétablissement et me libère.</p>
      <p>
        Tout cela a duré une dizaine de minutes, je vais donc devoir attendre mon épouse un certain temps dans le hall.
        C’est nettement plus animé que ce matin. Pour tuer le temps je joue avec mon IPhone. Au bout d’un moment, comme
        il fait beau, je sors. Personne ne se soucie de moi, je rejoins tranquillement la rue pour attendre. J’aperçois
        mon épouse dans un taxi et me dépêche de monter à bord.</p>
      <p>
        Ma femme me demande si c’était bien utile qu’elle vienne me chercher, je lui raconte ma sortie. Elle me dit que
        j’aurais très bien pu rentrer seul ...</p>
      <p>
        Ma prise en charge en ambulatoire m’aura pris une matinée. Je trouve que c’est quand même un sacré progrès !</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA10",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA10.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*

@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}
*/

</style>