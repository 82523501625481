<template>
  <subpart-a>
    <h2 class="white_title">I. Le parcours amont</h2>
    <h3 class="white_title">La consultation d'anesthésie</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>J’arrive à la consultation d’anesthésie avec mon ¼ d’heure d’avance habituel. C’est un bâtiment à l’écart du
        bâtiment principal. Heureusement je l’avais repéré auparavant car il n’est pas bien signalé. La salle d’attente
        est tout aussi animée et vaste que celle des consultations.</p>
      <p>
        Le processus est apparemment compliqué, première étape administrative, deuxième étape entretien avec un
        anesthésiste, troisième étape digitalisation !</p>
      <p>
        L’attente commence. Au bout de 25 minutes mon nom est affiché. Dans le box indiqué une secrétaire prend le
        questionnaire d’anesthésie que j’ai rempli avant de venir. Elle me demande de retourner dans la salle et
        d’attendre l’appel de mon nom. Je commence à trouver le temps long. J’en ai marre de jouer avec mon mobile.
        Au bout d’une demi-heure, mon nom s’affiche enfin. Dans le bureau de consultation je suis reçu par une
        anesthésiste très aimable qui parcourt le questionnaire et me pose des questions sur mon passé médical, mes
        éventuelles allergies, comment j’ai supporté les précédentes interventions, ... elle consigne tout ce que je lui
        dis dans son ordinateur. On parle aussi de mon activité.</p>
      <p>
        Elle me confirme que ce sera une anesthésie locorégionale et que ce ne sera pas douloureux. Elle me rappelle les
        consignes à observer, me demande si j’ai des questions, ... Elle m’informe alors que ce ne sera pas
        nécessairement elle qui me prendra en charge lors de l’intervention. Dommage!
        Elle me souhaite une bonne intervention et me revoilà à attendre... la digitalisation. Quel nom barbare, en quoi
        cela peut-il consister ?</p>
      <p>
        Encore ¼ d’heure et je me retrouve dans un autre box ou je suis accueilli par une secrétaire qui vérifie sur son
        ordinateur que mon dossier de préadmission est bien complet et me libère. J’ai été digitalisé !!</p>
      <p>
        Finalement, je suis resté près de 2 heures, ce qui me paraît quand même beaucoup.</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA05",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA05.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*

@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}
*/


</style>