export default [
        {
            catname: 'Prise en charge', catref: 'priseencharge', catkeywords: [
                'Confidentialité', 'Ponctualité', 'Rapidité', 'Sécurité'
            ], picto: 'P',
            label_out: (label) => label + ' de la prise en charge'
        },
        {
            catname: 'Attitude', catref: 'attitudeprofessionnels', catkeywords: [
                'Amabilité', 'Considération', 'Écoute', 'Identification'
            ], picto: 'A',
            label_out: (label) => label + ' des professionnels'
        },
        {
            catname: 'Explications', catref: 'infoexpldoc', catkeywords: [
                'Clarté', 'Précision', 'Exhaustivité ', 'Homogénéité'
            ], picto: 'E',
            label_out: (label) => label + ' des informations'
        },
        {
            catname: 'Documentation', catref: 'documentation', catkeywords: [
                'Accessibilité', 'Intelligibilité', 'Utilité', 'Présentation'
            ], picto: 'D',
            label_out: (label) => label + ' de la documentation'
        },
        {
            catname: 'Organisation', catref: 'organisation', catkeywords: [
                'Simplicité', 'Fluidité', 'Efficacité', 'Souplesse'
            ], picto: 'O',
            label_out: (label) => label + ' de l’organisation'
        },
        {
            catname: 'Infrastructures', catref: 'infra', catkeywords: [
                'Adaptation', 'Signalétique', 'Confort', 'Propreté'
            ], picto: 'I',
            label_out: (label) => label + ' des infrastructures'
        }
    ]
