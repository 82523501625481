<template>
  <subpart-a>
    <h2 class="white_title">III. Le parcours aval</h2>
    <h3 class="white_title">Au domicile</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>Il n’est pas loin de 13h00, je ressens plus une gêne qu’une douleur, je prends le paracétamol qui m’a été
        prescrit. Après le déjeuner je contacte l’infirmière qui va changer quotidiennement mon pansement. Elle le fera
        au cabinet infirmier. Je ne ressens pas de douleur.</p>
      <p>
        Le lendemain, pas évident de se doucher avec la main droite en évitant de mouiller la gauche, même protégée par
        un plastique. En fin d’après-midi j’ai rendez-vous pour mon premier changement de pansement. Toujours pas de
        douleur, mais je continue à prendre du paracétamol. La plaie est bien nette, nettoyée et désinfectée, j’ai
        troqué mon gros pansement contre un pansement très fin autocollant qui a du mal à tenir en place. A peine revenu
        de mon rendez-vous il a fallu que je le consolide, C’est un peu un pansement à la « Dubout » mais on le changera
        demain, alors ...</p>
      <p>
        J’ai arrêté de prendre du paracétamol dès le deuxième jour. Les pansements ont toujours du mal à tenir. Je
        retourne au bureau pour travailler.</p>
      <p>
        15 jours se sont écoulés. Plus de points de suture, la cicatrice est nette et à l’air libre, je peux enfin me
        doucher normalement. J’ai ma visite post opératoire dans deux semaines. Tout s’est bien déroulé, pas de
        complications, mais je ne ressens pas d’amélioration notable, la sensibilité tarde à revenir ... Selon certains
        avis j’en aurais pour plusieurs mois.</p>
      <p>
        A la réflexion je me dis que l’ambulatoire, dans ces conditions c’est parfait. Dans le cas contraire, en cas de
        problème, je me demande comment j’aurais fait,.. comment savoir si ce qui arrive est ou non normal ? J’aurais
        peut-être hésité à téléphoner, par peur de déranger pour rien, j’aurais certainement stressé ...</p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA11",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA11.png');
  /*background-repeat: no-repeat;*/
  /*background-size: 110%;*/
  /*padding-bottom: 25%;*/
  /*border: 1px solid grey;*/
  /*background-position: 0 -40px;*/
}
/*

@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}
*/

</style>