<template>
  <subpart-a>
    <h2 class="white_title">II. Le parcours en Hôpital de jour</h2>
    <h3 class="white_title">La préparation</h3>
    <hr/>
    <div class="subpart_header">
    </div>
    <div class="speakable">
      <p>Arrivés au niveau 0, un étage au-dessus, une jeune femme en blouse nous accueille. Elle commence par se
        présenter : « Bonjour, je suis Pascale, une des infirmières du service, bienvenue, suivez-moi, mes collègues
        vont vous conduire à vos chambres ». Dans le couloir, à l’appel de notre nom, nous sommes pris en charge deux
        par deux, par un infirmier. Le nôtre nous demande notre motif d’hospitalisation, le nom du chirurgien et la
        nature de l’intervention pour laquelle nous sommes là.</p>
      <p>
        Nous sommes introduits dans une chambre de 4 lits, séparés par des rideaux. Notre accompagnateur nous montre les
        casiers dans lesquels nous devrons mettre nos vêtements, une fois revêtus de la tenue hospitalière qu’il nous
        fournit et dont il nous explique le mode d’emploi. Blouse, charlotte, sur chaussures et pantalon pour que nous
        puissions descendre au bloc, par nos propres moyens, en toute sérénité. Il nous précise que nous devons retirer
        lunettes, montre, bijoux, prothèse dentaire amovible, ... Il nous demande si nous avons des questions, nous
        indique la salle de bains et quitte la chambre en laissant la porte entrouverte.</p>
      <p>
        Pas évident de taper, sans lunettes, un code pour verrouiller un casier individuel. Heureusement l’infirmier
        repasse pour voir si tout se passe bien ... et m’aide aimablement à fermer le casier qui m’a été attribué.
        Un peu plus tard, un autre infirmier entre dans la chambre, il s’enquiert de nos noms puis s’adresse à mon
        voisin, vérifie son identité, le motif de l’intervention et le nom du chirurgien qui doit la pratiquer, vérifie
        qu’il a respecté les consignes, qu’il est bien à jeun. Il lui donne ensuite des explications que j’écoute
        attentivement depuis le lit où je suis allongé.</p>
      <p>
        Plus tard, le même processus se déroule en ce qui me concerne avec un autre infirmier. Je lui fais remarquer que
        l’on m’a déjà demandé deux fois les mêmes informations, ce à quoi il me répond gentiment que c’est une question
        d’identito-vigilance. Je ne peux pas m’empêcher de noter que les informations qu’il me fournit diffèrent pour
        partie de celles que j’ai précédemment entendues. Que ce soit pour des raisons d’Identito-vigilance ou le fait
        d’une multiplicité d’intervenants, tout cela laisse planer un doute sur l’organisation ...</p>
      <p>Il est un peu moins de 8h00, allongés sur nos lits nous attendons en silence que l’on vienne nous chercher. De
        temps en temps quelqu’un entrouvre la porte de la chambre, jette un coup d’œil et la referme... Au bout d’une
        vingtaine de minutes un brancardier vient chercher mon voisin, ... me voilà seul à attendre ! </p>
    </div>
  </subpart-a>
</template>

<script>
import subpartA from './subpartA.vue';

export default {
  name: "subpartA07",
  components: {
    subpartA
  }
}
</script>

<style scoped>

.subpart_header {
  background-image: url('../../public/img/partA07.png');
/*  background-repeat: no-repeat;*/
/*  background-size: 110%;*/
/*  padding-bottom: 25%;*/
/*  border: 1px solid grey;*/
/*  background-position: 0 -40px;*/
}
/*

@media screen and (max-width: 1200px) {
  .subpart_header {
    background-size: 110%;
    background-position: 0;
  }
}

@media screen and (max-width: 1000px) {
  .subpart_header {
    background-position: right top;
    padding-bottom: 30%;
    background-size: 135%;
  }
}

@media screen and (max-width: 600px) {
  .subpart_header {
    background-position: right 30%;
    padding-bottom: 30%;
    background-size: 150%;
  }
}
*/


</style>