<template>
  <div class="home">
    <h2>L’accueil de Gilles B. par les deux professionnels qui vont recueillir son expérience</h2>
    <img alt="Main illustration" src="../../public/img/bd_expat.png"/>
  </div>
  Vous débutez votre parcours avec...
  <button class="big" @click="$router.push('/partA/partA01')">une première démonstration</button>.
</template>

<script>

export default {
  name: 'subpartABD', methods: {
    nextsubpart() {
      this.$emit('nextsubpart');
    }
  },
  props: ['subpart']
}
</script>


<style scoped>
.home {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  text-align: center;
}

.home IMG {
  max-width: 100%;
}
</style>